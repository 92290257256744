import React from 'react'

function Icon() {
  return (
    <svg width="60" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="swap-line" clipPath="url(#clip0_1049_3004)">
<path id="Vector" d="M32.3889 16.3844C32.3889 25.4051 25.2752 32.7178 16.4999 32.7178C7.7248 32.7178 0.611084 25.4051 0.611083 16.3844C0.611083 7.36386 7.7248 0.0511052 16.4999 0.0511048C25.2752 0.0511044 32.3889 7.36386 32.3889 16.3844ZM29.2111 16.3844C29.2111 9.16787 23.5201 3.31777 16.4999 3.31777C9.47984 3.31777 3.78887 9.16787 3.78887 16.3844C3.78887 23.601 9.47984 29.4511 16.4999 29.4511C23.5201 29.4511 29.2111 23.601 29.2111 16.3844ZM11.7333 24.5511L6.17221 19.6511L11.7333 14.7511L11.7333 18.0178L18.0888 18.0178L18.0888 21.2844L11.7333 21.2845L11.7333 24.5511ZM21.2666 8.21776L26.8277 13.1178L21.2666 18.0178L21.2666 14.7511L14.911 14.7511L14.911 11.4844L21.2666 11.4844L21.2666 8.21776Z" fill="#D0D0CF"/>
</g>
<defs>
<clipPath id="clip0_1049_3004">
<rect width="60" height="31" fill="white" transform="translate(0.5 32) rotate(-90)"/>
</clipPath>
</defs>
</svg>
  )
}

export default Icon
