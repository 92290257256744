import InforHomepage from '../Landing/InforHomepage/'
import ContentHomepage from '../Landing/ContentHomepage/'
import AboutHomepage from '../Landing/AboutHomepage/'
import FooterLanding from 'components/FooterLanding'

export default function LandingPage() {
  return (
    <>
      <InforHomepage />
      <ContentHomepage />
      {/* <AboutHomepage /> */}
      <FooterLanding />
    </>
  )
}
