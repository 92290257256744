import { IconDiscord, IconMedium, IconTelegram, IconTwitter, LogoFooterMbImage } from 'assets'
import React from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

const listSocial = [
  {
    icon: <IconDiscord />,
    link: '',
  },
  {
    icon: <IconTwitter />,
    link: 'https://twitter.com/astro_swapio',
  },
  {
    icon: <IconMedium />,
    link: '',
  },
  {
    icon: <IconTelegram />,
    link: '',
  },
]

const FooterWrapDesktop = styled.div`
  color: rgba(255, 241, 211, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 50px 70px 30px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`

const FooterWrapMobile = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b1b19;
  width: 100%;
  height: 100%;
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`

const FooterLanding = () => {
  // todo responsive
  return (
    <>
      <FooterWrapDesktop>
        <div>Copyright © Astro</div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {listSocial.map((item, idx) => (
            <a href={item.link} key={idx}>
              {item.icon}
            </a>
          ))}
        </div>
      </FooterWrapDesktop>
      <FooterWrapMobile>
        <img src={LogoFooterMbImage} alt="" />
      </FooterWrapMobile>
    </>
  )
}

export default FooterLanding
