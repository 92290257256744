import { t, Trans } from '@lingui/macro'
import { useState, useContext } from 'react'
import { Percent } from 'dependencies/sdk-core'
import styled, { ThemeContext } from 'styled-components/macro'

import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/misc'
import { darken } from 'polished'
import { useSetUserSlippageTolerance, useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import {  Separator } from '../SearchModal/styleds'

enum SlippageError {
  InvalidInput = 'InvalidInput',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const FancyButton = styled.button`
  padding: 8px 18px;
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 8px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid #FFF;

  background: var(--Neutral-50, #ECEBEB);
  outline: none;
 
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  padding: 20px 10px;
  :hover {
    cursor: pointer;
  }
  border: 1px solid #FFF;
  border-radius: 8px;
  background: var(--Neutral-50, #ECEBEB);
  color: ${({ active, theme }) => (active ? theme.black : theme.text1)};
  line-height:0;
  :focus{
    background: var(--Primary-400, #FFCF6D)
    
  }
  @media only screen and (max-width: 640px) {
    padding: 20px 2px;
  }
`

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
display: flex;
padding: 8px 18px;
justify-content: center;
align-items: center;
gap: 12px;
flex: 1 0 0;
align-self: stretch;
height: 42px;
  border: ${({ theme, active, warning }) =>
    active ? `1px solid ${warning ? theme.red1 : theme.primary1}` : warning && `1px solid ${theme.red1}`};
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;
    background: var(--Neutral-50, #ECEBEB);
    color: var(--Neutral-700, #2B2A28);
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`

interface TransactionSettingsProps {
  placeholderSlippage: Percent // varies according to the context in which the settings dialog is placed
}

export default function TransactionSettings({ placeholderSlippage }: TransactionSettingsProps) {
  const theme = useContext(ThemeContext)

  const userSlippageTolerance = useUserSlippageTolerance()
  const setUserSlippageTolerance = useSetUserSlippageTolerance()

  const [deadline, setDeadline] = useUserTransactionTTL()

  const [slippageInput, setSlippageInput] = useState('')
  const [slippageError, setSlippageError] = useState<SlippageError | false>(false)

  const [deadlineInput, setDeadlineInput] = useState('')
  const [deadlineError, setDeadlineError] = useState<DeadlineError | false>(false)

  function parseSlippageInput(value: string) {
    // populate what the user typed and clear the error
    setSlippageInput(value)
    setSlippageError(false)

    if (value.length === 0) {
      setUserSlippageTolerance('auto')
    } else {
      const parsed = Math.floor(Number.parseFloat(value) * 100)

      if (!Number.isInteger(parsed) || parsed < 0 || parsed > 5000) {
        setUserSlippageTolerance('auto')
        if (value !== '.') {
          setSlippageError(SlippageError.InvalidInput)
        }
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    }
  }

  const tooLow = userSlippageTolerance !== 'auto' && userSlippageTolerance.lessThan(new Percent(5, 10_000))
  const tooHigh = userSlippageTolerance !== 'auto' && userSlippageTolerance.greaterThan(new Percent(1, 100))

  function parseCustomDeadline(value: string) {
    // populate what the user typed and clear the error
    setDeadlineInput(value)
    setDeadlineError(false)

    if (value.length === 0) {
      setDeadline(DEFAULT_DEADLINE_FROM_NOW)
    } else {
      try {
        const parsed: number = Math.floor(Number.parseFloat(value) * 60)
        if (!Number.isInteger(parsed) || parsed < 60 || parsed > 180 * 60) {
          setDeadlineError(DeadlineError.InvalidInput)
        } else {
          setDeadline(parsed)
        }
      } catch (error) {
        console.error(error)
        setDeadlineError(DeadlineError.InvalidInput)
      }
    }
  }

  return (
    <AutoColumn gap="16px">
      <AutoColumn gap="16px">
        <RowBetween>
          <RowFixed>
          <TYPE.black fontWeight={500} fontSize={14} color={'var(--White, #FFFBF3)'}>
            <Trans>Max. slippage</Trans>
          </TYPE.black>
          <QuestionHelper
            text={
              <Trans>Your transaction will revert if the price changes unfavorably by more than this percentage.</Trans>
            }
          />
          </RowFixed>
          <RowFixed style={{borderRadius: '8px', background: 'var(--Neutral-500, #3D3B38)', padding:'4px 8px'}}>
            <TYPE.white color={'var(--Neutral-200, #ACABA9)'}>Auto</TYPE.white>
          </RowFixed>
          
        </RowBetween>
        <RowBetween style={{borderRadius:'12px', background: 'var(--Neutral-500, #3D3B38)', padding:'16px', gap:'8px'}}>
          <Option
            onClick={() => {
              parseSlippageInput('')
            }}
            active={userSlippageTolerance === 'auto'}

          >
            <TYPE.black fontWeight={700} fontSize={16} >
            <Trans>Auto</Trans>
          </TYPE.black>
          </Option>

          <Option
            onClick={() => {
              parseSlippageInput('')
            }}
            active={userSlippageTolerance === 'auto'}
            
          >
            <TYPE.black fontWeight={700} fontSize={16} >
            <Trans>Custom</Trans>
          </TYPE.black>
          </Option>
          <OptionCustom active={userSlippageTolerance !== 'auto'} warning={!!slippageError} tabIndex={-1}>
            <RowBetween>
              {tooLow || tooHigh ? (
                <SlippageEmojiContainer>
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null}
              <Input
                placeholder={placeholderSlippage.toFixed(2)}
                value={
                  slippageInput.length > 0
                    ? slippageInput
                    : userSlippageTolerance === 'auto'
                    ? ''
                    : userSlippageTolerance.toFixed(2)
                }
                onChange={(e) => parseSlippageInput(e.target.value)}
                onBlur={() => {
                  setSlippageInput('')
                  setSlippageError(false)
                }}
                color={slippageError ? 'red' : ''}
              />
              %
            </RowBetween>
          </OptionCustom>
        </RowBetween>
        {slippageError || tooLow || tooHigh ? (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError ? 'red' : '#F3841E',
            }}
          >
            {slippageError ? (
              <Trans>Enter a valid slippage percentage</Trans>
            ) : tooLow ? (
              <Trans>Your transaction may fail</Trans>
            ) : (
              <Trans>Your transaction may be frontrun</Trans>
            )}
          </RowBetween>
        ) : null}
      </AutoColumn>
      <Separator style={{background: '#F1F1F1'}}/>
      <AutoColumn gap="16px">
        <RowBetween>
          <RowFixed>
          <TYPE.black fontWeight={500} fontSize={14} color={'var(--White, #FFFBF3)'}>
            <Trans>Transaction deadline</Trans>
          </TYPE.black>
          <QuestionHelper
            text={t`Your transaction will revert if it is pending for more than this period of time.`}
          />
          </RowFixed>
          <RowFixed style={{borderRadius: '8px', background: 'var(--Neutral-500, #3D3B38)', padding:'4px 8px'}}>
            <TYPE.white color={'var(--Neutral-200, #ACABA9)'}>{deadlineInput}m</TYPE.white>
          </RowFixed>
          
        </RowBetween>
        
        <RowFixed style={{width:'100%',borderRadius:'12px', background: 'var(--Neutral-500, #3D3B38)', padding: '16px 20px', gap:'8px'}}>
          <RowFixed style={{width:'100%',borderRadius:'8px', background: 'var(--Neutral-700, #2B2A28)', padding:'8px 18px', gap:'2px'}}>

          <OptionCustom style={{ width: '80px', padding:'0px', height:'20px', border: '0px solid var(--Neutral-500, #3D3B38)',background: 'var(--Neutral-700, #2B2A28)'}} warning={!!deadlineError} tabIndex={-1}>
            <Input
              placeholder={(DEFAULT_DEADLINE_FROM_NOW / 60).toString()}
              value={
                deadlineInput.length > 0
                  ? deadlineInput
                  : deadline === DEFAULT_DEADLINE_FROM_NOW
                  ? ''
                  : (deadline / 60).toString()
              }
              onChange={(e) => parseCustomDeadline(e.target.value)}
              onBlur={() => {
                setDeadlineInput('')
                setDeadlineError(false)
              }}
              color={deadlineError ? 'red' : ''}
              style={{background: 'var(--Neutral-700, #2B2A28)', fontWeight:500, textAlign:'right', color:'var(--Neutral-50, #ECEBEB)'}}
            />
          </OptionCustom>
          <TYPE.body style={{  color: 'var(--Neutral-50, #ECEBEB)', fontWeight:500, lineHeight: '20px'}} fontSize={14} >
            <Trans>minutes</Trans>
          </TYPE.body>
          </RowFixed>
        </RowFixed>
      </AutoColumn>
    </AutoColumn>
  )
}
