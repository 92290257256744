import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <g filter="url(#filter0_b_1143_3195)">
        <rect width="32" height="32" fill="url(#paint0_linear_1143_3195)" fillOpacity="0.1" rx="4"></rect>
      </g>
      <path
        fill="url(#paint1_linear_1143_3195)"
        d="M25.204 9.922a7.168 7.168 0 01-1.85.56c.298-.054.736-.623.91-.853.265-.348.467-.744.596-1.17 0-.031.03-.076 0-.099a.097.097 0 00-.098 0 9.04 9.04 0 01-2.19.903.144.144 0 01-.082.002.151.151 0 01-.071-.043 2.204 2.204 0 00-.192-.212 3.972 3.972 0 00-1.046-.718 3.489 3.489 0 00-1.63-.284 3.77 3.77 0 00-1.547.451c-.474.276-.891.65-1.225 1.102a4.276 4.276 0 00-.745 1.58 4.6 4.6 0 00-.038 1.67c0 .095 0 .109-.076.095-3.033-.474-5.521-1.616-7.554-4.067-.09-.109-.136-.109-.209 0-.884 1.426-.455 3.684.651 4.799.149.149.302.293.464.428a3.63 3.63 0 01-1.455-.428c-.085-.06-.132-.027-.136.08-.012.151-.012.302 0 .452.089.72.356 1.402.775 1.977.418.574.973 1.02 1.607 1.292.154.07.316.123.48.158-.469.098-.95.113-1.424.045-.102-.022-.14.036-.102.14.625 1.806 1.982 2.357 2.977 2.664.136.022.272.022.425.058l-.025.028c-.294.568-1.48.952-2.025 1.15a6.887 6.887 0 01-3.105.425c-.166-.027-.204-.022-.246 0-.043.023 0 .072.046.118.213.149.426.28.647.406a9.857 9.857 0 002.075.903c3.735 1.092 7.937.289 10.74-2.668 2.203-2.32 2.977-5.522 2.977-8.727 0-.122.14-.194.221-.258a7.57 7.57 0 001.46-1.616.494.494 0 00.101-.325c0-.068 0-.054-.08-.018z"
      ></path>
      <defs>
        <filter
          id="filter0_b_1143_3195"
          width="40"
          height="40"
          x="-4"
          y="-4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1143_3195"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1143_3195" result="shape"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1143_3195"
          x1="1.867"
          x2="28.385"
          y1="0"
          y2="33.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFAF0"></stop>
          <stop offset="1" stopColor="#FFEFCB"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1143_3195"
          x1="8.067"
          x2="20.825"
          y1="8"
          y2="26.359"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEFCB"></stop>
          <stop offset="1" stopColor="#E8D0A1"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
