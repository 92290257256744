import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { darken } from 'polished'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import { ExternalLink, TYPE } from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import { CardNoise } from '../earn/styled'
import Modal from '../Modal'
import Row, { RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
import NetworkCard from './NetworkCard'
import UniBalanceContent from './UniBalanceContent'
import { AstroLogo, MenuIcon } from 'assets'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  background-color: #221a0b;
  border-radius: 30px;
  max-width: 1288px;
  display: grid;
  grid-template-columns: 1fr 0px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;

  /* Background slide effect on scroll. */

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
    grid-template-columns: auto 1fr;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `}
`
const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: start;
  @media only screen and (max-width: 640px) {
    display: none;
  }
`
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
  `};
`
const Menu = styled.div`
  display: block;
  @media only screen and (min-width: 640px) {
    display: none;
  }
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  
  `};
`

const HeaderLinks = styled(Row)`
  width: fit-content;
  padding: 4px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    
  `};
  border-radius: 9999px;
  background: var(--Primary-900, #735823);
  margin-right: 14px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
    
  `};
`
const BoxBalance = styled.div`
  padding: 8px;
  border-radius: 57px;
  background: var(--ffc-85-f, rgba(117, 95, 255, 0.2));
  margin-right: 14px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

// const UniIcon = styled.div`
//   transition: transform 0.3s ease;
//   :hover {
//     transform: rotate(-5deg);
//   }
// `

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;

  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 14px;

  &.${activeClassName} {
    color: ${({ theme }) => theme.white};
  }

  :hover,
  :focus {
    background-color: #99a1bd14;
  }
`

export const StyledNavA = styled.a.attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;

  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 14px;

  &.${activeClassName} {
    color: ${({ theme }) => theme.white};
  }

  :hover,
  :focus {
    background-color: #99a1bd14;
  }
`

// const StyledExternalLink = styled(ExternalLink).attrs({
//   activeClassName,
// })<{ isActive?: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: 500;

//   &.${activeClassName} {
//     border-radius: 12px;
//     font-weight: 600;
//     color: ${({ theme }) => theme.white};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//     text-decoration: none;
//   }

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//       display: none;
// `}
// `
const SideBar = styled.div<{ isOpen: boolean }>`
  border-radius: 0px 16px 16px 0px;
  border-right: 2px solid #383838;

  background: rgba(27, 27, 25, 0.7);

  box-shadow: 8px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(200px) !important;

  border-radius: 0px 16px 16px 0px;
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  z-index: 99;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0px)' : 'translateX(-260px)')};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  padding: 20px;
`

export const SideBarMenuMobile = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
  return (
    <SideBar isOpen={isOpen}>
      <HeaderRow onClick={handleClose}>
        <Title href=".">
          <AstroLogo />
        </Title>
      </HeaderRow>
      <HeaderLinks
        style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '24px' }}
        onClick={handleClose}
      >
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Swap</Trans>
        </StyledNavLink>
        <StyledNavLink
          id={`pool-nav-link`}
          to={'/pool'}
          isActive={(match, { pathname }) =>
            Boolean(match) ||
            pathname.startsWith('/add') ||
            pathname.startsWith('/remove') ||
            pathname.startsWith('/increase') ||
            pathname.startsWith('/find')
          }
        >
          <Trans>Pool</Trans>
        </StyledNavLink>
        <StyledNavA id={`docs`} href="https://docs.astroswap.info/" target="_blank">
          <Trans>Docs</Trans>
        </StyledNavA>
        <StyledNavA id={`community`} href="https://twitter.com/astro_swapio" target="_blank">
          <Trans>Community</Trans>
        </StyledNavA>
      </HeaderLinks>
    </SideBar>
  )
}

export default function Header() {
  const { account } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const handleToggleMenu = () => {
    setIsOpenMobile((prev) => !prev)
  }

  const handleCloseMenu = () => {
    setIsOpenMobile(false)
  }

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <SideBarMenuMobile isOpen={isOpenMobile} handleClose={handleCloseMenu} />
      {isOpenMobile && (
        <div
          onClick={handleCloseMenu}
          style={{
            position: 'fixed',
            inset: 0,
            backgroundColor: 'rgb(0, 0, 0,0.1)',
            backdropFilter: 'blur(10px)',
            zIndex: 10,
          }}
        />
      )}
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <LeftSideContainer>
        <HeaderRow>
          <Title href=".">
            <AstroLogo />
          </Title>
        </HeaderRow>
        <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            <Trans>Swap</Trans>
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/increase') ||
              pathname.startsWith('/find')
            }
          >
            <Trans>Pool</Trans>
          </StyledNavLink>
          <StyledNavA id={`docs`} href="https://docs.astroswap.info/" target="_blank">
            <Trans>Docs</Trans>
          </StyledNavA>
          <StyledNavA id={`community`} href="https://twitter.com/astro_swapio" target="_blank">
            <Trans>Community</Trans>
          </StyledNavA>
        </HeaderLinks>
      </LeftSideContainer>
      <Menu onClick={() => handleToggleMenu()}>
        <MenuIcon />
      </Menu>

      <HeaderControls>
        <HeaderElement>
          <HideSmall>
            <NetworkCard />
          </HideSmall>
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? (
                    <Dots>
                      <Trans>Claiming UNI</Trans>
                    </Dots>
                  ) : (
                    <Trans>Claim UNI</Trans>
                  )}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BoxBalance>
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500} color="white">
                  <Trans>{userEthBalance?.toSignificant(4)} MNT</Trans>
                </BalanceText>
              </BoxBalance>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
