import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Activity } from 'react-feather'
import { t, Trans } from '@lingui/macro'
import styled, { css } from 'styled-components/macro'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
import PortisIcon from '../../assets/images/portisIcon.png'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
import { fortmatic, injected, portis, walletconnect, walletlink } from '../../connectors'
import useENSName from '../../hooks/useENSName'
import { useHasSocks } from '../../hooks/useSocksBalance'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import Identicon from '../Identicon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { FallbackWrapper } from 'components/Header/NetworkCard'
import { isMobile } from 'react-device-detect'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  border: none;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 9999px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
  background: var(--Gradient, linear-gradient(316deg, #ffc44d 27.52%, #ffda8f 96.08%));
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};

  color: '#000000';
  font-weight: 500;
  :hover,
  :focus {
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 16px;
  width: fit-content;
  font-weight: 500;
  color: '#000000 !important';
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

const ModalOption = styled.div`
  background-color: rgb(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  min-width: 290px;
  min-height: 100px;
  position: absolute;
  margin-top: 230px;
  z-index: 2;
  right: 10px;
  padding: 0 1rem 1rem 1rem;
  border-radius: 20px;
`
const OptionGrid = styled.div`
  display: grid;
  padding-top: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
  `};
`
const Option = styled.div`
  /* background-color: #fff; */
  padding: 1rem;
  outline: none;
  border: none;
  border-radius: 20px;
  width: 100% !important;
  color: #fff;
  font-weight: 500;
  &:hover {
    background-color: #99a1bd14;
  }
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Sock() {
  return (
    <span role="img" aria-label={t`has socks emoji`} style={{ marginTop: -4, marginBottom: -4 }}>
      🧦
    </span>
  )
}

// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={16}>
        <img src={WalletConnectIcon} alt={'WalletConnect'} />
      </IconWrapper>
    )
  } else if (connector === walletlink) {
    return (
      <IconWrapper size={16}>
        <img src={CoinbaseWalletIcon} alt={'CoinbaseWallet'} />
      </IconWrapper>
    )
  } else if (connector === fortmatic) {
    return (
      <IconWrapper size={16}>
        <img src={FortmaticIcon} alt={'Fortmatic'} />
      </IconWrapper>
    )
  } else if (connector === portis) {
    return (
      <IconWrapper size={16}>
        <img src={PortisIcon} alt={'Portis'} />
      </IconWrapper>
    )
  }
  return null
}

function Web3StatusInner() {
  const { account, connector, error, deactivate } = useWeb3React()
  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()
  const [showModal, setShowModal] = useState(false)

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()
  const isSafePal = window.ethereum && window.ethereum.isSafePal

  const handleConnect = () => {
    localStorage.setItem('isLogout', 'false')
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        event.target &&
        event.target instanceof Node &&
        !componentRef.current.contains(event.target) &&
        !modalRef.current?.contains(event.target)
      ) {
        setShowModal(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return removeEventListener('click', handleClickOutside)
  }, [])

  const switchChain = useCallback(async () => {
    try {
      await window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1388' }],
      })
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      // @ts-ignore
      if (err.code === 4902) {
        await window.ethereum?.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'Mantle Mainnet',
              chainId: '0x1388',
              iconUrls: ['https://icons.llamao.fi/icons/chains/rsz_mantle.jpg'],
              nativeCurrency: {
                name: 'Mantle',
                decimals: 18,
                symbol: 'MNT',
              },
              rpcUrls: ['https://rpc.mantle.xyz'],
            },
          ],
        })
      }
    }
  }, [window.ethereum])

  const handleDisconnect = () => {
    deactivate()
    localStorage.setItem('isLogout', 'true')
  }

  if (account) {
    return (
      <>
        <Web3StatusConnected
          id="web3-status-connected"
          pending={hasPendingTransactions}
          onClick={() => setShowModal(!showModal)}
          ref={modalRef}
        >
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>
                <Trans>{pending?.length} Pending</Trans>
              </Text>
              <Loader stroke="white" />
            </RowBetween>
          ) : (
            <>
              {hasSocks ? <Sock /> : null}
              <Text>{ENSName || shortenAddress(account)}</Text>
            </>
          )}
          {!hasPendingTransactions && connector && <StatusIcon connector={connector} />}
        </Web3StatusConnected>
        {showModal && (
          <ModalOption ref={componentRef}>
            <OptionGrid onClick={() => setShowModal(!showModal)}>
              <Option onClick={toggleWalletModal}>Wallet</Option>
              <Option onClick={toggleWalletModal}>Recent Transactions</Option>
              <Option style={{ color: '#E8006F' }} onClick={handleDisconnect}>
                Disconnect
              </Option>
            </OptionGrid>
          </ModalOption>
        )}
      </>
    )
  } else if (error) {
    return (
      <Web3StatusError>
        <NetworkIcon />
        <Text onClick={toggleWalletModal}>
          {error instanceof UnsupportedChainIdError ? <Trans>Wrong Network</Trans> : <Trans>Error</Trans>}
        </Text>
        <FallbackWrapper onClick={switchChain}>
          <Trans>Switch</Trans>
        </FallbackWrapper>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnected id="connect-wallet" onClick={isMobile && isSafePal ? handleConnect : toggleWalletModal}>
        <Text>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Web3StatusConnected>
    )
  }
}

export default function Web3Status() {
  const { account } = useWeb3React()
  // const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  /* if (!contextNetwork.active && !active) {
    return null
  } */

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
