import React from 'react'
import {
  Title,
  SwapAbout,
  Money,
  Bitcoin,
  ArrowGreen,
  BgLandingImage,
  CoinPrimaryImage,
  AstroImage,
} from '../../../assets'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from '../../../theme'
import { Link } from 'react-router-dom'
import BoxTokenPrice from './components/BoxTokenPrice'

export default function InforHomepage() {
  const PageContainer = styled.div`
    padding: 20px 12px;
    padding-bottom: 48px;

    height: 100%;
    z-index: 2;

    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      max-width: 1288px;
    }
  `

  const WrapImageSwap = styled.div`
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      width: 100%;
      overflow: hidden;
    }
  `

  const ImageSwap = styled.img`
    transform: translate(32%, 4%);
    width: 82%;
    height: 100%;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      transform: translate(0, 0);
      transform: scale(2);
      margin-top: 120px;
      margin-bottom: 100px;
      width: 100%;
    }
  `

  const GroupButton = styled.div`
    display: inline-flex;
    gap: 16px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  `

  const ContentContainer = styled.div<{}>``
  const AboutContentContainer = styled.div<{}>`
    display: flex;
    align-items: start;
    justify-content: space-between;
    overflow: hidden;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      flex-direction: column;
    }
  `
  const IntroContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      display: grid;
      padding-top: 60px;
      padding-bottom: 20px;
      gap: 10rem;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      display: none;
    }
  `

  const AboutContent = styled.div`
    position: absolute;
    padding-top: 1rem;
    @media (min-width: 1024px) {
      padding-top: 4.5rem;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      position: relative;
    }

    color: #f5f5f5;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
  `
  const AboutContentTitle = styled.div`
    color: #f5f5f5;
    font-size: 28px;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
  `
  const AboutContentText = styled.p`
    width: 60%;
    color: #fffbf3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 40px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      font-size: 11.485px;
      width: 100%;
      line-height: 17.228px;
    }
  `
  const StyledGetStartedButton = styled(Link)`
    background: linear-gradient(316deg, #ffc44d 27.52%, #ffda8f 96.08%);
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;

    border-radius: 43px;
    border: none;
    color: #111010;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `
  const StyledLearnMoreButton = styled.a`
    background: #735823;
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;

    border-radius: 43px;
    border: none;
    color: #fffbf3;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `

  const ImageWallet = styled.div`
    align-items: center;
    position: absolute;
    inset: 0;
    flex: 1;
    background-image: url(${BgLandingImage});
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 90%;
    background-blend-mode: color-dodge;
    z-index: -1;
  `

  const Balloon = styled.div`
    width: 405px;
    height: 405px;
    flex-shrink: 0;
    border-radius: 100%;
    opacity: 0.4;
    background: linear-gradient(316deg, #ffc44d 27.52%, #ffda8f 96.08%);
    position: absolute;
    filter: blur(120px);
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      display: none;
    }
  `

  const ChainCoinTitle = styled.div`
    color: #fffbf3;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      font-size: 22.97px;
      line-height: 34.455px;
    }
  `

  const TitleInfo = styled.div`
    color: #fff1d4;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      font-size: 51.683px;
      line-height: 57.426px;
    }
  `

  return (
    <PageContainer>
      {/* todo responsive */}
      <Balloon
        style={{
          bottom: '-15%',
          left: '-14%',
        }}
      />
      <Balloon
        style={{
          bottom: '-68%',
          right: '0%',
        }}
      />
      <ImageWallet />
      <ContentContainer>
        <AboutContentContainer>
          <div>
            <AboutContent>
              <div
                style={{
                  marginBottom: '24px',
                }}
              >
                <ChainCoinTitle>ASTROSWAP</ChainCoinTitle>
                <TitleInfo>Swap and Pool</TitleInfo>
              </div>
              <AboutContentText>
                AstroSwap is a groundbreaking Concentrated Liquidity Market Maker Decentralized Exchange (DEX) built on
                Mantle.
              </AboutContentText>
              <GroupButton>
                <StyledGetStartedButton to="/swap">Get Started</StyledGetStartedButton>
                <StyledLearnMoreButton href="https://docs.astroswap.info/" target="_blank">
                  Learn More
                </StyledLearnMoreButton>
              </GroupButton>
            </AboutContent>
          </div>
          <WrapImageSwap>
            <ImageSwap src={CoinPrimaryImage} alt="" />
          </WrapImageSwap>
        </AboutContentContainer>
        <BoxTokenPrice />
        {/* <ValueToken>
          {data.map((item) => (
            <div
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'inline-flex',
                gap: 12,
                width: '140px',
              }}
              key={item.id}
            >
              <img style={{ width: 30, height: 30, borderRadius: 99 }} src={item.iconToken} alt={item.symbol} />
              <div style={{}}>
                <div style={{ display: 'inline-flex' }}>
                  <span
                    style={{
                      color: '#F1F1F1',
                      fontSize: 16,
                      fontFamily: 'Inter',
                      fontWeight: 600,
                    }}
                  >
                    {item.symbol}
                  </span>
                  <div style={{ position: 'relative' }}>
                    <span
                      style={{
                        left: 18,
                        top: 0,
                        position: 'absolute',
                        textAlign: 'right',
                        color: '#00E85D',
                        fontSize: 14,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {item.percentage}
                    </span>
                    <img style={{ position: 'relative' }} src={item.arrowicon} />
                  </div>
                </div>
                <div
                  style={{
                    color: '#F1F1F1',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                  }}
                >
                  {item.amount}
                </div>
              </div>
            </div>
          ))}
        </ValueToken> */}
        <IntroContainer>
          <img
            style={{
              zIndex: -1,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={AstroImage}
            alt=""
          />

          <div style={{ paddingTop: '1.5rem' }}>
            <AboutContentTitle>What Is AstroSwap?</AboutContentTitle>
            <p
              style={{
                color: '#787878',
                fontSize: 16,
                fontFamily: 'Inter',
                fontWeight: 400,
                lineHeight: 2,
              }}
            >
              {`AstroSwap is leveraging Mantle's technological advancements, catering to both new and
              experienced traders. The platform is distinguished by low transaction fees, ease of use, robust security
              measures, and a user-focused design. AstroSwap is committed to providing a reliable, efficient, and
              profitable trading experience, positioning itself as a key player in the decentralized trading space.`}
            </p>
          </div>
        </IntroContainer>
      </ContentContainer>
    </PageContainer>
  )
}
