import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <g filter="url(#filter0_b_1143_3192)">
        <rect width="32" height="32" fill="url(#paint0_linear_1143_3192)" fillOpacity="0.1" rx="4"></rect>
      </g>
      <path
        fill="url(#paint1_linear_1143_3192)"
        d="M23.42 9.351c2.41 3.487 3.6 7.42 3.155 11.948a.067.067 0 01-.028.048 17.746 17.746 0 01-5.335 2.65.07.07 0 01-.075-.025 13.965 13.965 0 01-1.09-1.743.067.067 0 01.037-.094 10.979 10.979 0 001.665-.78.067.067 0 00.006-.11 8.527 8.527 0 01-.332-.257.066.066 0 00-.07-.008c-3.45 1.568-7.23 1.568-10.72 0a.067.067 0 00-.07.01 9.214 9.214 0 01-.33.254.067.067 0 00.007.112c.531.3 1.084.564 1.664.78a.066.066 0 01.037.093c-.314.611-.68 1.193-1.09 1.744a.07.07 0 01-.075.024 17.805 17.805 0 01-5.327-2.65.07.07 0 01-.028-.049c-.371-3.916.386-7.882 3.152-11.947a.062.062 0 01.028-.025 17.758 17.758 0 014.343-1.325.07.07 0 01.07.033c.188.328.403.749.549 1.092a16.506 16.506 0 014.877 0c.145-.336.353-.764.54-1.092A.067.067 0 0119.05 8a17.8 17.8 0 014.343 1.325.053.053 0 01.027.025zm-9.04 7.447c.016-1.158-.841-2.116-1.918-2.116-1.068 0-1.917.95-1.917 2.116 0 1.166.866 2.116 1.917 2.116 1.068 0 1.917-.95 1.917-2.116zm7.088 0c.017-1.158-.841-2.116-1.917-2.116-1.068 0-1.918.95-1.918 2.116 0 1.166.867 2.116 1.918 2.116 1.076 0 1.917-.95 1.917-2.116z"
      ></path>
      <defs>
        <filter
          id="filter0_b_1143_3192"
          width="40"
          height="40"
          x="-4"
          y="-4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1143_3192"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1143_3192" result="shape"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1143_3192"
          x1="1.867"
          x2="28.385"
          y1="0"
          y2="33.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFAF0"></stop>
          <stop offset="1" stopColor="#FFEFCB"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1143_3192"
          x1="6.578"
          x2="18.548"
          y1="8"
          y2="28.095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEFCB"></stop>
          <stop offset="1" stopColor="#E8D0A1"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
