import React from 'react'
import {
  totalvolume,
  user,
  volume24h,
  earned,
  feesaved,
  Bitcoin,
  ArrowGreen,
  OptionSwap,
  OptionLaunch,
  benefit1,
  benefit2,
  benefit3,
  benefit4,
} from '../../../assets'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from '../../../theme'
import { background } from 'styled-system'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'

export default function ContentHomepage() {
  const data = [
    {
      id: 1,
      symbol: 'BTC',
      iconToken: Bitcoin,
      percentage: '1.44%',
      arrowicon: ArrowGreen,
      amount: '37,505.05$',
    },
    {
      id: 2,
      symbol: 'BTC',
      iconToken: Bitcoin,
      percentage: '1.44%',
      arrowicon: ArrowGreen,
      amount: '37,505.05$',
    },
    {
      id: 3,
      symbol: 'BTC',
      iconToken: Bitcoin,
      percentage: '1.44%',
      arrowicon: ArrowGreen,
      amount: '37,505.05$',
    },
    {
      id: 4,
      symbol: 'BTC',
      iconToken: Bitcoin,
      percentage: '1.44%',
      arrowicon: ArrowGreen,
      amount: '37,505.05$',
    },
    {
      id: 5,
      symbol: 'BTC',
      iconToken: Bitcoin,
      percentage: '1.44%',
      arrowicon: ArrowGreen,
      amount: '37,505.05$',
    },
    {
      id: 6,
      symbol: 'BTC',
      iconToken: Bitcoin,
      percentage: '1.44%',
      arrowicon: ArrowGreen,
      amount: '37,505.05$',
    },
  ]

  const dataMenuOptions = [
    {
      id: 1,
      title: 'Swap Token',
      content: 'Buy, sell, and explore tokens on Mantle.',
      button: 'Trade Token',
      bg: OptionSwap,
      link: '/swap',
    },
    {
      id: 2,
      title: 'Launchpools',
      content: 'Provide liquidity for tokens on Mantle.',
      button: 'Add Liquidity',
      bg: OptionLaunch,
      link: '/pool',
    },
  ]
  const dataListBenefits = [
    {
      id: 1,
      image: benefit1,
      title: 'Introduction',
      content:
        'The platform is distinguished by low transaction fees, ease of use, robust security measures, and a user-focused design. AstroSwap is committed to providing a reliable, efficient, and profitable trading experience, positioning itself as a key player in the decentralized trading space.',
    },
    {
      id: 2,
      image: benefit2,
      title: 'Price Impact',
      content:
        'AstroSwap ensures denser liquidity pools in these areas. This design is particularly advantageous for large trades, as the available liquidity in targeted ranges is higher, thereby minimizing the price shift typically caused by substantial orders.',
    },
    {
      id: 3,
      image: benefit3,
      title: 'Slippage',
      content:
        'The other relevant detail to consider when approaching swaps with the AstroSwap protocol is slippage. Slippage is the term we use to describe alterations to a given price that could occur while a submitted transaction is pending.',
    },
    {
      id: 4,
      image: benefit4,
      title: 'Safety Checks',
      content:
        'Proportional in this instance takes into account many factors, including the relative price of one token in terms of the other, slippage, price impact, and other factors related to the open and adversarial nature of Ethereum.',
    },
  ]
  const dataStatisticalList = [
    {
      id: 1,
      image: totalvolume,
      title: 'Total Volume',
      value: '$300.52M',
    },
    {
      id: 2,
      image: volume24h,
      title: 'Volume 24h',
      value: '$1.55M',
    },
    {
      id: 3,
      image: user,
      title: 'Monthly active users',
      value: '50.53K',
    },
    {
      id: 4,
      image: feesaved,
      title: 'Trade Fee Saved',
      value: '$4.63M',
    },
    {
      id: 5,
      image: earned,
      title: 'LP Earned',
      value: '$16.56M',
    },
  ]

  const PageContainer = styled.div`
    z-index: 3;

    background: #fff1d4;
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      margin: 0 auto;
    }
  `

  const ContentHomepage = styled.div`
    padding: 32px 12px;
    padding-top: 96px;
    max-width: 1288px;
    width: 100%;
  `
  const TitleOption = styled.div`
    color: #2d2410;
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    line-height: 32px;
    word-wrap: break-word;
    padding-bottom: 28px;

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 17px;
    }
  `

  const MenuOption = styled.div`
    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      justify-content: space-between;
      display: inline-flex;
      gap: 4rem;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      display: flex;
      flex-direction: column;
    }
  `

  const BoxOption = styled.div<{ background?: string }>`
    position: relative;
    background-color: #fff9ed;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.25) inset;
    border-radius: 20px;
    width: 375px;
    margin-bottom: 32px;
    background-image: ${({ background }) => `url(${background})`};
    background-blend-mode: ${({ background }) => (background?.includes('optionswap') ? 'difference' : 'luminosity')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;

    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      width: 616px;
      height: 283px;
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
      width: 100%;
      height: 178px;
    }
  `
  const BackgroundBoxOption = styled.div`
    border-radius: 20px;
    background-color: #fff9ed;
    position: absolute;
    box-shadow: 50px 0px 11px #fff9ed;
    width: 150px;
    height: 100%;
  `
  const ImageOption = styled.img`
    border-radius: 20px;

    width: 375px;
    height: 180px;

    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      width: 600px;
      height: 280px;
    }
  `

  const ContentOption = styled.div`
    color: #1e1e1e;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    position: absolute;
    top: 50px;
    left: 20px;
    width: 220px;
    @media screen and (min-width: ${BREAKPOINTS.md}px) {
      top: 140px;

      width: 330px;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      width: 80%;
      font-weight: 600;
    }
  `

  const ButtonOption = styled(Link)`
    padding: 8px 20px;
    background: #ffe6b2;
    border: 1px solid var(--Primary-400, #ffcf6d);
    border-radius: 999px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
    color: #2d2410;
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    border: none;
    margin-top: 12px;
  `
  const Benefits = styled.div`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    display: inline-flex;
    padding: 22px 0;
  `
  const ListBenefits = styled.div`
    display: none;
    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      width: 100%;
      justify-content: space-between;
      display: inline-flex;
      gap: 8px;
    }
  `

  const TitleBenefits = styled.span`
    color: #081832;
    font-size: 18px;
    font-family: Inter;
    font-weight: 700;
    line-height: 26px;
    word-wrap: break-word;
  `
  const ContentBenefits = styled.span`
    color: #787878;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    align-self: stretch;
    text-align: center;
  `

  const StatisticalList = styled.div`
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;

    padding-top: 24px;
    padding-bottom: 24px;
    background: #fff9ed;
    border-radius: 24px;
    align-items: center;

    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(5, minmax(0, 1fr));

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
    }
  `
  // const TitleSatisticalist = styled.span`
  //   color: #081832;
  //   font-size: 14px;
  //   font-family: Inter;
  //   font-weight: 600;
  //   line-height: 20px;
  //   white-space: nowrap;
  // `
  // const ValueSatisticalist = styled.span`
  //   color: #1e1e1e;
  //   font-size: 18px;
  //   font-family: Inter;
  //   font-weight: 700;
  //   line-height: 26px;
  //   word-wrap: break-word;
  // `

  // const MobileBenefits = styled.div`
  //   display: flex;
  //   flex-direction: column;
  //   gap: 18px;
  //   @media screen and (min-width: ${BREAKPOINTS.sm}px) {
  //     display: none;
  //   }
  // `

  const Wall = styled.div`
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      display: none;
    }
  `
  const StyledSwiper = styled(Swiper)`
    overflow: hidden;
    width: 100%;

    height: 100%;
    border-radius: 20px !important;
    position: relative;
    & > .swiper-wrapper {
      display: flex;
      width: max-content;
      border-radius: 20px !important;
    }
    & > .swiper-pagination {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin: 15px auto;
      @media (min-width: 1024px) {
        margin: 20px auto;
      }

      height: 10px;
      width: fit-content;
      & > .swiper-pagination-bullet {
        cursor: pointer;
        aspect-ratio: 1/1;
        height: 100%;
        background: white;
        opacity: 0.2;
        border-radius: 99999px;
      }
      & > .swiper-pagination-bullet.swiper-pagination-bullet-active {
        opacity: 1;
        background: var(--Linear, linear-gradient(180deg, #4ac1ff -21.25%, #0071d0 105%));
      }
    }

    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
      display: none;
    }
  `

  return (
    <PageContainer>
      <ContentHomepage>
        <MenuOption>
          {dataMenuOptions.map((item) => (
            <div key={item.id}>
              <TitleOption>{item.title}</TitleOption>
              <BoxOption background={item.bg}>
                <div style={{}}>
                  {/* todo: */}
                  <BackgroundBoxOption></BackgroundBoxOption>
                  {/* <ImageOption src={item.bg} /> */}
                </div>
                <ContentOption>
                  {item.content}
                  <br />
                  <ButtonOption to={item.link}>{item.button}</ButtonOption>
                </ContentOption>
              </BoxOption>
            </div>
          ))}
        </MenuOption>
        {/* todo responsive */}

        <Benefits>
          <TitleOption>Our Benefits</TitleOption>
          <ListBenefits>
            {dataListBenefits.map((item) => (
              <div
                key={item.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  style={{
                    width: '289px',
                    height: '155px',
                    objectFit: 'cover',
                  }}
                  src={item.image}
                />
                <TitleBenefits>{item.title}</TitleBenefits>
                <ContentBenefits>{item.content}</ContentBenefits>
              </div>
            ))}
          </ListBenefits>

          <StyledSwiper
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {dataListBenefits.map((item, idx) => (
              <SwiperSlide key={idx}>
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <img
                    style={{
                      width: '271.25px',
                      height: '155px',
                      objectFit: 'cover',
                    }}
                    src={item.image}
                    alt={`Slide ${idx}`}
                  />
                  <TitleBenefits>{item.title}</TitleBenefits>
                  <ContentBenefits>{item.content}</ContentBenefits>
                </div>
              </SwiperSlide>
            ))}
          </StyledSwiper>

          {/* <MobileBenefits>
            {dataListBenefits.map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  style={{
                    width: '271.25px',
                    height: '155px',
                    objectFit: 'cover',
                  }}
                  src={item.image}
                />
                
              </div>
            ))}
          </MobileBenefits> */}
        </Benefits>
        {/* <StatisticalList>
          {dataStatisticalList.map((item, idx) => (
            <div
              style={{
                position: 'relative',
              }}
              key={item.id}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '0 16px',
                  gap: '20px',
                  minWidth: '130px',
                }}
              >
                <img src={item.image} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TitleSatisticalist>{item.title}</TitleSatisticalist>
                  <ValueSatisticalist>{item.value}</ValueSatisticalist>
                </div>
              </div>
              {idx + 1 !== dataStatisticalList.length && (
                <Wall
                  style={{
                    position: 'absolute',
                    backgroundColor: 'rgba(117, 95, 255, 0.20)',
                    height: '80px',
                    width: '1px',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    margin: 'auto 0px',
                  }}
                />
              )}
            </div>
          ))}
        </StatisticalList> */}
      </ContentHomepage>
    </PageContainer>
  )
}
