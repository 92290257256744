import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <g filter="url(#filter0_b_1143_3201)">
        <rect width="32" height="32" fill="url(#paint0_linear_1143_3201)" fillOpacity="0.1" rx="4"></rect>
      </g>
      <path
        fill="url(#paint1_linear_1143_3201)"
        d="M8.98 15.097s7.077-3.073 9.532-4.155c.94-.433 4.13-1.818 4.13-1.818s1.474-.606 1.35.865c-.04.606-.367 2.727-.695 5.021-.49 3.247-1.022 6.796-1.022 6.796s-.082.996-.777 1.17c-.696.172-1.841-.607-2.046-.78-.163-.13-3.067-2.078-4.13-3.03-.287-.26-.614-.78.04-1.385a160.544 160.544 0 004.295-4.329c.49-.52.982-1.731-1.064-.26-2.904 2.121-5.767 4.113-5.767 4.113s-.655.433-1.882.043c-1.227-.39-2.658-.91-2.658-.91s-.982-.648.695-1.341z"
      ></path>
      <defs>
        <filter
          id="filter0_b_1143_3201"
          width="40"
          height="40"
          x="-4"
          y="-4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1143_3201"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1143_3201" result="shape"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1143_3201"
          x1="1.867"
          x2="28.385"
          y1="0"
          y2="33.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFAF0"></stop>
          <stop offset="1" stopColor="#FFEFCB"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1143_3201"
          x1="8.933"
          x2="20.097"
          y1="9"
          y2="25.064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEFCB"></stop>
          <stop offset="1" stopColor="#E8D0A1"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
