import { MaxButton } from 'pages/Pool/styleds'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
  
  min-width: 460px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 360px;
  `};
`

export const SmallMaxButton = styled(MaxButton)`
  background: rgba(115, 88, 35, 0.40);
  font-size: 14px;
  color: var(--Primary-600, #D9A741);
  border: none;
`

export const ResponsiveHeaderText = styled(Text)`
  color: var(--White, #FFFBF3);
  font-size: 32px;
  font-weight: 700;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     font-size: 24px
  `};
`
