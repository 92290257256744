import React from 'react'

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <g filter="url(#filter0_b_1143_3198)">
        <rect width="32" height="32" fill="url(#paint0_linear_1143_3198)" fillOpacity="0.1" rx="4"></rect>
      </g>
      <path
        fill="url(#paint1_linear_1143_3198)"
        d="M17.112 15.96c0 2.737-2.259 4.96-5.056 4.96C9.258 20.92 7 18.697 7 15.96S9.258 11 12.056 11c2.797 0 5.056 2.223 5.056 4.96zm5.539 0c0 2.573-1.135 4.664-2.528 4.664s-2.528-2.09-2.528-4.664c0-2.573 1.135-4.664 2.528-4.664s2.527 2.08 2.527 4.664zm2.269 0c0 2.31-.393 4.183-.888 4.183-.494 0-.887-1.873-.887-4.183 0-2.31.393-4.183.887-4.183.495 0 .888 1.873.888 4.183z"
      ></path>
      <defs>
        <filter
          id="filter0_b_1143_3198"
          width="40"
          height="40"
          x="-4"
          y="-4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"></feGaussianBlur>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1143_3198"></feComposite>
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1143_3198" result="shape"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1143_3198"
          x1="1.867"
          x2="28.385"
          y1="0"
          y2="33.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFAF0"></stop>
          <stop offset="1" stopColor="#FFEFCB"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1143_3198"
          x1="8.045"
          x2="14.264"
          y1="11"
          y2="25.145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEFCB"></stop>
          <stop offset="1" stopColor="#E8D0A1"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
